<!-- Version: 1.0 -->
<template>
  <div ref="timePicker">
    <div class="eh-timepicker-input-box">
      <input
        @blur="isFocus = false"
        @focus="isFocus = true"
        :placeholder="placeholder ? placeholder : 'HH:MM(:SS)'"
        :disabled="disabled"
        v-model="tempValue"
        class="eh-base-input eh-timepicker"
        :class="setClass"
      />
      <span @click="showTimer = !showTimer" class="eh-timepicker-icon">
        <b-icon icon="clock"></b-icon>
      </span>
      <div v-if="showTimer && !disabled" class="eh-timepicker-calendar">
        <b-time v-model="tempValue"></b-time>
      </div>
      <span v-if="label" class="eh-base-input-floating-label">
        {{ label }}
        <span v-if="required" class="text-danger">*</span>
      </span>
      <div class="eh-base-input-desc-error-box">
        <div v-if="description" class="eh-base-input-description">
          <div v-html="description"></div>
        </div>
        <div v-if="!state && error" class="eh-base-input-error-text">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import moment from "moment";
export default {
  name: "fp-time-picker",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    label: String,
    disabled: Boolean,
    required: Boolean,
    placeholder: String,
    description: String,
    error: String,
    textStyle: String, //$enums.InputTextStyle
    state: { type: Boolean, default: true },
    value: String,
    defaultValue: String,
  },
  data() {
    return {
      isFocus: false,
      tempValue: this.value,
      showTimer: false,
    };
  },
  watch: {
    /* tempValue(input) {
      }, */
    isFocus(input) {
      if (!input) {
        this.$emit("change", this.tempValue);
      }
    },
    value(input) {
      this.tempValue = input;
    },
  },
  computed: {
    setClass() {
      var temp = "";
      if (this.label) {
        temp += " with-label ";
      } else {
        temp += " without-label ";
      }
      if (this.state) {
        temp += " valid-input ";
      } else {
        temp += " invalid-input ";
      }
      return temp;
    },
  },
  methods: {
    clickOutOfBoxEvent(e) {
      if (!this.$refs.timePicker.contains(e.target)) {
        this.isFocus = false;
        this.showTimer = false;
      }
    },
    /* setTimeValue() {
    //console.log(this.value)
      const temp = this.value ? this.value : this.defaultValue;
      if (temp && moment(temp).isValid()) {
        this.$emit("change", moment(temp).format());
        return moment(temp).format("hh:mm:ss");
      } else if(temp && moment(moment().format('YYYY-MM-DD') + 'T' + temp).isValid()){
        return moment(moment().format('YYYY-MM-DD') + 'T' + temp).format('hh:mm:ss')
      }
      return "";
    }, */
  },
  mounted() {
    window.addEventListener("click", this.clickOutOfBoxEvent);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.clickOutOfBoxEvent);
  },
};
</script>
<style scoped>
input.text-field-input.input-light:focus ~ .input-text-placeholder,
input.text-field-input.input-light.not-empty ~ .input-text-placeholder {
  color: white;
  opacity: 1;
}
input.text-field-input.input-dark:focus ~ .input-text-placeholder,
input.text-field-input.input-dark.not-empty ~ .input-text-placeholder {
  color: #00000099;
  opacity: 1;
}
input.text-field-input.not-valid-input ~ .error-text,
.input-checkbox.invalid-input .error-text {
  color: red;
  font-size: 12px;
  padding-left: 5px;
  margin-top: -5px;
  display: block;
}
input ~ .error-text,
.input-checkbox .error-text {
  display: none;
  position: absolute;
}
input.text-field-input:focus {
  outline: none;
}
input.text-field-input:disabled {
  background: lightgray;
}
input.text-field-input.not-valid-input {
  border-color: red;
}
input.text-field-input:focus ~ .input-text-placeholder,
input.text-field-input.not-empty ~ .input-text-placeholder {
  top: -16px;
  left: 15px;
  font-size: 11px;
  opacity: 0.4;
  display: inline;
}
.text-field-input-box {
  position: relative;
  margin-top: 20px;
  padding-bottom: 12px;
}
.input-text-placeholder {
  opacity: 0.5;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 10px;
  transition: 0.2s ease all;
}

input.text-field-input {
  font-size: 14px;
  border: solid 3px;
  border-color: var(--eh-primary-5);
  border-radius: 10px;
  height: 40px;
  padding-left: 15px;
  width: 100%;
}
.input-text-description {
  font-size: 14px;
  margin-left: 10px;
  color: #00000099;
}

input:disabled + .input-time-icon {
  cursor: default;
}
</style>
